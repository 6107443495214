<template>
  <div class="modal fade" tabindex="-1" role="dialog" ref="mdlSeleccionarRequerimiento">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header text-center">
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
          <h3 class="modal-title">Seleccione requerimiento para crear cotización</h3>
        </div>
        <div class="modal-body">
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Fecha de solicitud</th>
                  <th>solicitud</th>
                  <th style="width:100px">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    1035
                  </td>
                  <td>
                    31/05/2020
                  </td>
                  <td>
                    Felicia Oliva A. Sanchez
                  </td>
                  <td>
                    <router-link
                      data-dismiss="modal"
                      :to="{ name: 'nuevoCotizacion', query: { id_requerimiento:10 } }"
                      class="btnInfo btn btn-default btn-xs"
                    >
                      <i class="fa fa-check"></i>
                      Seleccionar
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->
</template>

<script>

export default {  
  data: function() {
    return {
    
    }
  },
  methods: {
    mostrar() {
      var self = this
      $(self.$refs.mdlSeleccionarRequerimiento).modal("show")
      //self.cargarArticulo()
    },

    cargarArticulo: function() {
      
    }
  }
}
</script>


<style scoped>
.color-verde {
  color: #1AB394
}
</style>
